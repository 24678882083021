import Event, {EVENT_PDP_MORE_FROM_THIS_BRAND_CLICK } from "Util/Event";
import BaseEvent from "./Base.event";
export const SPAM_PROTECTION_DELAY = 200;

class MoreFromThisBrandName extends BaseEvent {
  bindEvent() {
    Event.observer(EVENT_PDP_MORE_FROM_THIS_BRAND_CLICK, ({ brand_name ='' ,product_sku ='' }) => {
      this.handle({
        brand_name,
        product_sku
      });
    });
  }
 
  handler({ brand_name = "", product_sku = "" }) {
    if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
      return;
    }

    this.pushEventData({
      brand_name,
      product_sku
    });
  }
}

export default MoreFromThisBrandName;
