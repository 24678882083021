
import PropTypes from "prop-types";
import { PureComponent } from "react";
import { connect } from "react-redux";

import ClubApparelDispatcher from "Store/ClubApparel/ClubApparel.dispatcher";
import { hideActiveOverlay } from "Store/Overlay/Overlay.action";
import { customerType } from "Type/Account";
import { setCrossSubdomainCookie } from "Util/Url/Url";
import MyAccountClubApparelOverlay from "./MyAccountClubApparelOverlay.component";
import {
  STATE_LINK,
  STATE_SUCCESS,
  STATE_VERIFY,
  STATE_DE_LINK_SUCCESS,
  STATE_AUTHENTICATION,
} from "./MyAccountClubApparelOverlay.config";
import { delinkAccount } from "./../../util/API/endpoint/ClubApparel/ClubApparel.enpoint.js";
import { showNotification } from "Store/Notification/Notification.action";
export const MyAccountDispatcher = import(
  "Store/MyAccount/MyAccount.dispatcher"
);
export const mapStateToProps = (_state) => ({
  customer: _state.MyAccountReducer.customer,
  country: _state.AppState.country,
  clubApparel: _state.ClubApparelReducer.clubApparel,
});

export const mapDispatchToProps = (dispatch) => ({
  hideActiveOverlay: () => dispatch(hideActiveOverlay()),
  linkAccount: (data) => ClubApparelDispatcher.linkAccount(dispatch, data),
  verifyOtp: (data) => ClubApparelDispatcher.verifyOtp(dispatch, data),
  getMember: (id) => ClubApparelDispatcher.getMember(dispatch, id),
  showNotification: (type, message) =>
    dispatch(showNotification(type, message)),
  autoEnroll: (data) => ClubApparelDispatcher.autoEnroll(dispatch, data),
  setPhoneNumber: (phone) =>
    MyAccountDispatcher.then(({ default: dispatcher }) =>
      dispatcher.setPhoneNumber(dispatch, phone)
    ),
    requestCustomerData: () => MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)),
});

export class MyAccountClubApparelOverlayContainer extends PureComponent {
  static propTypes = {
    linkAccount: PropTypes.func.isRequired,
    verifyOtp: PropTypes.func.isRequired,
    country: PropTypes.string.isRequired,
    customer: customerType,
    showNotification: PropTypes.func.isRequired,
    autoEnroll: PropTypes.func.isRequired,
    setPhoneNumber: PropTypes.func,
    clubApparel: PropTypes.object,
    requestCustomerData: PropTypes.func,
  };

  static defaultProps = {
    customer: null,
  };
  state = {
    state: this.props?.popup_state ? this.props?.popup_state : STATE_AUTHENTICATION,
    phone: "",
    error: "",
    isLoading: false,
    customerPhone: this.props?.customer?.phone || null,
    customerVerified: this.props?.customer?.isVerified || "0",
    isResendDisabled: true,
  };

  containerFunctions = () => ({
    linkAccount: this.linkAccount.bind(this),
    verifyOtp: this.verifyOtp.bind(this),
    autoEnrollCustomer: this.autoEnrollCustomer.bind(this),
    setLinkState: this.setLinkState.bind(this),
    handleCreateAccount: this.handleCreateAccount.bind(this),
    handleDeLinkAccount: this.handleDeLinkAccount.bind(this),
  });

  containerProps = () => {
    const {
      state,
      phone,
      countryPhoneCode,
      isLoading,
      error,
      customerPhone,
      customerVerified,
      isResendDisabled,
    } = this.state;
    return {
      state,
      phone,
      countryPhoneCode,
      isLoading,
      error,
      customerPhone,
      customerVerified,
      isResendDisabled,
    };
  };

  timer = null;


  componentWillUnmount() {
    const { hideActiveOverlay } = this.props;
    hideActiveOverlay()
    clearTimeout(this.timer);
  }
  setLinkState() {
    this.setState({ state: STATE_LINK, error: false });
  }

  handleCreateAccount(fields) {
    const {
      customer: { isVerified, phone, id, custom_attributes : { is_mobile_otp_verified } },
      customer
    } = this.props;
    if(phone == `${fields?.countryPhoneCode}${fields?.phone}` && is_mobile_otp_verified){
      this.autoEnrollCustomer();
    } else if (fields?.countryPhoneCode && fields?.phone) {
      this.linkAccount({
        countryPhoneCode: fields.countryPhoneCode,
        phone: fields.phone,
      });
    } else {
      this.setState({ state: STATE_LINK });
    }
  }
  async handleDeLinkAccount() {
    
    const {
      customer: { isVerified, phone, id },
      getMember,
      requestCustomerData,
    } = this.props;
    const { customerPhone } = this.state;
    if (customerPhone) {

      const response = await delinkAccount();
      
      if(response?.code === 200){
        this.setState({ state: STATE_DE_LINK_SUCCESS }, () => { getMember(id), requestCustomerData() });
      } 
    } 
  }
  
  async linkAccount(fields) {
    const {
      customer: { id },
      linkAccount,
      showNotification,
    } = this.props;
    const { phone, countryPhoneCode } = fields;
    const { state, customerPhone } = this.state;
    const formattedPhone =
      state == STATE_VERIFY ? customerPhone : `${countryPhoneCode}${phone}`;
    this.setState({
      isLoading: true,
      customerPhone: formattedPhone,
      isResendDisabled: true,
      error: false,
    });

    await linkAccount({
      customerId: id,
      mobileNo: formattedPhone.replace("+", "00"),
    }).then((response) => {
      if (response) {
        const { message , success, } = response;
        if (success) {
          if(typeof message === "string"){
            showNotification("success", message);
          }
          this.timer = setTimeout(() => {
            this.setState({ isResendDisabled: false });
          }, 30000);
          this.setState({
            state: STATE_VERIFY,
            phone: phone,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
          if(response?.error && typeof response?.error == "string") {
            showNotification(
              "error",
              response?.error
            );
          } 
        }
      } else {
        this.setState({
          error: false,
          isLoading: false,
        });
        showNotification(
          "error",
          __("Something went wrong! Please, try again!")
        );
      }
    }, this._handleError);
  }

  autoEnrollCustomer() {
    const {
      customer: { id, phone },
      autoEnroll,
      getMember,
      showNotification,
    } = this.props;
    const { customerPhone } = this.state;
    autoEnroll({ 
      phone_number: customerPhone ? customerPhone : phone,
    }).then((response) => {
      if (response?.data?.accountLinked) {
        this.setState(
          {
            state: STATE_SUCCESS,
            error: false,
            isLoading: false,
          },
          () => getMember(id)
        );
      } else {
        this.setState({
          error: false,
          isLoading: false,
        });
        showNotification(
          "error",
          __("Something went wrong! Please, try again!")
        );
      }
    }, this._handleError);
  }

  async saveCustomer() {
    const { setPhoneNumber } = this.props;
    const { customerPhone } = this.state;
    try {
      const updatedCustomerResponse = setPhoneNumber(customerPhone);
      setCrossSubdomainCookie("customerPrimaryPhone", customerPhone, "30");
    } catch (e) {
      console.log("Error", e);
    }
  }

  verifyOtp(fields) {
    const {
      customer: { id },
      verifyOtp,
      getMember,
      showNotification,
    } = this.props;
    const { otp } = fields;
    const { memberId, customerPhone } = this.state;
    this.setState({ isLoading: true });
    verifyOtp({
      customerId: id,
      otp,
      memberId,
      mobileNo: customerPhone.replace("+", "00"),
    }).then((response) => {
      if (response) {
        if (response?.code == 200 && response?.data?.isMember ) {
          this.setState(
            {
              state: STATE_SUCCESS,
              error: false,
              isLoading: false,
            },
            () => {
              getMember(id);
            }
          );
          this.saveCustomer();
        } else {
          this.setState({
            state: STATE_VERIFY,
            error: true,
            isLoading: false,
          });
        }
      } else {
        this.setState({
          error: false,
          isLoading: false,
        });
        showNotification(
          "error",
          __("Something went wrong! Please, try again!")
        );
      }
    }, this._handleError);
  }


  render() {
    return (
      <MyAccountClubApparelOverlay
        {...this.props}
        {...this.containerFunctions()}
        {...this.containerProps()}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccountClubApparelOverlayContainer);
