const BRAND_MAPPING = {
  cat: "كات",
  nautica: "نوتيكا",
  alba: "ألبا",
  altanus: "ألتانوس",
  "alexandre christie": "الكساندر كرستي",
  "aries gold": "اريس جولد",
  "armani exchange": "أرماني",
  azzaro: "ازارو",
  "beverly hills polo club watch": "بيفرلي هيلز بولو كلوب",
  coach: "كوتش",
  cover: "كوفر",
  dkny: "دكني",
  fiyta: "فيتا",
  fjord: "فيورد",
  freestyle: "فريستايل",
  giordano: "جيوردانو",
  giovine: "جيوفين",
  "ice watch": "أيس واتش",
  "issey miyake": "ايساي مياكي",
  "just cavalli": "جاست كافالي",
  naturalizer: "ناتشيراليزر",
  "lotty&lorry": "لوتي اند لوري",
  "michael kors": "مايكل كورس",
  "paris hilton": "باريس هيلتون",
  revani: "ريفاني",
  timberland: "تمبرلاند",
  "aldo accessories": "ألدو أكسيسوريز",
  "adams kids": "ادمز كيدز",
  anta: "انتا",
  aeropostale: " ايروبوستال",
  "p.s aeropostale": "ايروبوستال",
  ardene: "اردين",
  "austin reed": "أوستين ريد",
  "barbie bc": "باربي",
  crayola: "كرايولا",
  "fisher price bc": "فيشر برايس",
  frozen: "فروزن",
  "hot wheels": "هوت ويلز",
  joy: "جويْ",
  "tom tom": "توم توم",
  www: "دبليو دبليو دبليو",
  bandolino: "باندولينو",
  barbie: "باربي",
  "beverly hills polo club": "بيفرلي هيلز بولو كلوب",
  birkenstock: "بيركنستوك",
  papilo: "بابيلو",
  "bottega verde": "بوتيغا فيردي",
  "cath kidston": "كاث كيدستون",
  "calvin klein": "كالفين كلاين",
  "charming charlie": "تشارمينغ تشارلي",
  "charles & keith": "تشارلز آند كيث",
  gucci: "غوتشي",
  versace: "فيرساكي",
  "foot petal": "فوت بيتال",
  avengers: "افنجرز / المنتقمون",
  "barbie ge": "باربي",
  bvlgari: "بولغاري",
  "carolina herrera": "كارولينا هريرا",
  carrera: "كاريرا",
  dunhill: "دنهل",
  empora: "ايمبورا",
  "hugo boss": "هوغو بوس",
  "hush puppies": "هاش بابيز",
  "jacqueline de yong": "جاكولين دو يونج",
  lanvin: "لانفين",
  majorette: "ماجورتي",
  mia: "ميا",
  "name it": "نيم ات",
  "new york transit": "نيويورك ترانزيت",
  only: "اونلي",
  "polo ralph lauren": "بولو رالف لورين",
  prada: "برادا",
  "ralph lauren": "رالف لورين",
  "real techniques": "ريل تيكنيكس",
  seden: "سدن",
  "st dupont": "اس تي دوبنت",
  valentino: "فلانتينو",
  "vero moda": "فيرو مودا",
  wwe: "دابليو دابليو اي",
  garage: "غاراج",
  inglot: "انجلوت",
  "barbie jfk": "باربي",
  hasbro: "هاسبرو",
  "kenneth cole": "كينيث كول",
  "lc waikiki": "اٍل سي واي كيكي",
  "levi's": "ليفايز",
  "levis kids": "ليفايز",
  "la vie en rose": "لافي ان روز",
  bzees: "بيزيس",
  "franco sarto": "فرانكو سارتو",
  "life stride": "لايف سترايد",
  naryka: "ناريكا",
  "new balance": "نيو بالانس",
  pedro: "بيدرو",
  "r&b": "أر آند بي",
  "red tape": "ردتيب",
  reebok: "ريبوك",
  bullboxer: "بولبوكسر",
  capodarte: "كابودارتي",
  eddicuomo: "إيديكومو",
  fergalicious: "فارغاليشوس",
  flexx: "فليكس",
  franco: "فرانكو",
  inkass: "إينكاس",
  "loucos and santos": "لوكوس آند سانتوس",
  "patrizio dolci": "باتريزيو دولتشي",
  "rocket dog": "روكيت دوغ",
  "comfort plus": "كومفورت بلس",
  "sg farada": "فرادا",
  steptronic: "ستيبترونيك",
  superga: "سوبرجا",
  tahari: "تاهاري",
  via: "فيا",
  "yellow box": "يلو بوكس",
  "sam & libby": "سام آند ليبي",
  berastogi: "بيراستوجي",
  bibi: "بيبي",
  "dr mauch": "دكتور ماوخ",
  felimini: "فليميني",
  "gc shoes": "جي سي شو",
  pikolinos: "بيكولينوس",
  redtape: "ريد تاب",
  adidas: "اديداس",
  anatomic: "أناتوميك",
  asics: "اسيكس",
  blowfish: "بلوفيش",
  "butterfly twist": "باترفلاي تويست",
  caliente: "كالينتي",
  crocs: "كروكس",
  havaianas: "هافاياناز",
  heelys: "هيليز",
  jansport: "جان سبورت",
  lacoste: "لاكوست",
  lecoq: "ليكوك",
  nike: "نايك",
  "onitsuka tiger": "أونيت سوكا تايجر",
  palladium: "بالاديوم",
  people: "بيبول",
  polo: "بولو",
  ryka: "ريكا",
  saucony: "ساكوني",
  "taf emu": "تاف",
  unosole: "انوسول",
  vans: "فانس",
  vibram: "فيبرام",
  "the children's place": "ذا تشيلدرنز بليس",
  teva: "تيفا",
  "tommy hilfiger": "تومي هيلفيغر",
  "tommy hilfiger bags": "تومي هيلفيغر",
  "tommy hilfiger footwear": "تومي هيلفيغر",
  "tommy hilfiger fragrance": "تومي هيلفيغر",
  "tommy hilfiger kwt": "تومي هيلفيغر",
  "tommy hilfiger sunglass": "تومي هيلفيغر",
  "tommy hilfiger socks": "تومي هيلفيغر",
  "tommy hilfiger underwear": "تومي هيلفيغر",
  "tommy watches": "تومي هيلفيغر",
  toms: "تومز",
  ugg: "يو جي جي",
  "z generation": "زد جينيريشن",
  converse: "كونفرس",
  "dollar plus": "دولار بلس",
  amazonas: "امازونس",
  dupe: "ديوب",
  flossy: "فلوسي",
  herschel: "هيرشل",
  "dune london": "ديون لندن",
  "easy spirit": "إيزي سبيريت",
  "christian dior": "كريستيان ديور",
  dior: "ديور",
  fendi: "فيندي",
  "giorgio armani": "جورج ارماني",
  "ak anne klein": "إيه كيه آن كلاين",
  "aldo acc": "ألدو أكسيسوريز",
  "athletes co": "آثليتس كو",
  bhpc: "بفرلي هيلز بولو كلوب",
  "jack rodger": "جاك رودجر",
  klauskobec: "كلاوسكوبك",
  mountroyal: "ماونترويال",
  guess: "جيس",
  "nine west": "ناين وست",
  puma: "بوما",
  aigner: "أيجنر",
  aldo: "ألدو",
  "juicy couture": "جوسي كوتور",
  "anne klein": "آن كلاين",
  disney: "ديزني",
  "fisher price": "فيشر برايس",
  bourjois: "بورجوا",
  isadora: "إيزادورا",
  maxfactor: "ماكس فاكتور",
  revlon: "ريفلون ",
  rimmel: "ريميل لندن ",
  ferrari: "فيراري",
  skechers: "سكيتشرز",
  "call it spring": "كول ات سبرنج",
  "toms x prabal gurung": "تومز اكس برابال غورونغ",
  "jimmy choo": "جيمي شو",
  polaroid: "بولارويد ",
  "adidas originals": "اديداس اورجينال",
  "dr. mauch": " دكتور ماوخ",
  "captain america": "كابتن امريكا",
  simba: "سيمبا",
  lego: "ليغو",
  rituals: "ريتوالز ",
  prestige: "برستيج",
  mesauda: "ميساودا",
  maybelline: "ميبيلين ",
  "lilsoft baby": "ليل سوفت بيبي",
  "belily world": "بيليلي وورلد",
  "elegant kids 2000": "إليجانت كيدز 2000",
  impo: "إيمبو",
  "mix no. 6": "ميكس نمبر 6",
  "kelly & katie": "كيلي آند كاتي",
  "warfield & grand": "وورفيلد آند جراند",
  "supply lab": "سبلاي لاب",
  "nanette lepore": "نانيت ليبور",
  "seven 91": "سڤن 91",
  "cl by laundry": "سي ال باي لوندري",
  wonderbra: "ونديربرا",
  lovable: "لوفيبل",
  "3pommes": "3 بوم",
  fellimini: "فيليميني",
  volatile: "فولاتيل",
  "naturino express": "ناتورينو إكسبرس",
  "de blossom collection": "داى بلوزوم كوليكشن",
  josmo: "جوزمو",
  "laura ashley": "لورا آشلي",
  "kensie girl": "كينسي قيرل",
  rachel: "راشيل",
  nina: "نينا",
  "rock & soda": "روك أند صودا",
  "zigi soho": "زيجي سوهو",
  "crown vintage": "كراون فينتيج",
  qupid: "كيوبيد",
  abella: "أبيلا",
  robeez: "روبيز",
  "van eli": "فانيلي",
  "olive & edie": "أوليف أند إيدي",
  "little diva": "ليتل ديفا",
  "original penguin": "أوريجينال بينجوين",
  margaritaville: "مارجريتفيل",
  "step & stride": "ستيب اند سترايد",
  "spring footwear": "سبرينغ فوت وير",
  "r&b premium": " أر آند بي بريميوم",
  "aiden & mason": "ايدن أند ماسون",
  "audrey brooke": "أودري بروك",
  bearpaw: "بير باو",
  bogs: "بوقز",
  "charles david": "تشالز ديفيد",
  "cole haan": "كول هان",
  "de blossom girl": "دى بلوزوم قيرل",
  "deer stags": "دير ستاقز",
  "de la rentis": "دي لا رنتيس",
  eurosoft: "يوروسوفت",
  "giorgio brutini": "جورجيو بروتيني",
  gunmetal: "جونميتال",
  indigo: "إنديغو",
  isola: "ايزولا",
  "italian shoemakers": "إيطاليان شو ميكرز",
  jellypop: "جيلي بوب",
  "kenneth cole reaction": "كينيث كول ري آكشن",
  "mercanti fiorentini": "ميركانتي فيورنتيني",
  "moda spana": "مودا سبانا",
  "motion adventure play": "موشن أدفينتشر بلاي",
  "n.y.l.a": "إن. واي. إل. آي",
  "not rated": "نوت ريتيد",
  "nunn bush": "نان باش",
  patrizia: "باتريسيا",
  privileged: "بريفيلجد",
  "scott david": "سكوت ديفيد",
  "spring step": "سبرينغ ستيب",
  "union bay": "يونيون باى",
  sugar: "شوجر",
  vaneli: "ڤانيلي",
  wanted: "ونتيد",
  "white mountain": "وايت ماونتن",
  "lulu townsend": "لولو تاون سند",
  "kate + alex cuffaro": "كيت + أليكس كوفارو",
  "la diva": "لا ديفا",
  "urban expressions": "آربان إكسبريشنز",
  "london fog": "لندن فوغ",
  "crystal avenue": "كريستال أفنيو",
  "betsey johnson": "بيتسي جونسون",
  "violet ray": "فيوليت راى",
  carlos: "كارلوس",
  memoi: "ميمويه",
  "happy plugs": "هابي بلقز",
  hue: "هيو",
  "hot sox": "هوت سوكس",
  ankit: "آنكيت",
  "aston grey": "آستون جريه",
  "sock it to me": "سوك ات تو مي",
  "cayler & sons": "كيلر اند سنس",
  "coral blue": "كورال بلو",
  fessura: "فيسورا",
  "sean alan": "سين ألان",
  zaxy: "زاكسي",
  caparros: "كاباروس",
  "com+sens": "كوم بلَس سينز",
  petalia: "بيتاليا",
  "rugged bear": "رجيد بير",
  corkys: "كوركيس",
  "nicole miller": "نيكول ميلر",
  born: "اوبري لين",
  "aubrey lynn": "اوبري لين",
  "l'artiste by spring step": "لارتيست باي سبرينغ ستيب",
  crevo: "كريڤو",
  "k. bell": "كاي بيل",
  "elite by corkys": "إليت باي كوركيس",
  "amelia grace": "اميليا غريس",
  sasha: "ساشا",
  "emilie m.": "إيميلي إم",
  "zylist ": "زيلست",
  trendyol: "ترينديول",
  "studio italia": "ستوديو ايطاليا ",
  hotsoles: "هوت سولس",
  bebe: "بيبي",
  "dolce vita": "دولتشي فيتا",
  "marc fisher": "مارك فيشر",
  andiamo: "أنديامو",
  "andrew geller": "آندرو غيلر",
  "ciao bella": "تشاو بيلا",
  seychelles: "سيشيلز",
  "coach and four": "كوتش آند فور",
  "bare traps": "بير ترابس",
  "bc footwear": "بي سي فوتوير",
  diba: "ديبا",
  "b.o.c": "بي أوه سي",
  "earth origins": "إرث أوريجنز",
  "koolaburra by ugg": "كولابورا باي يو جي جي",
  "indigo rd.": "إنديغو رود",
  "straw studios": "سترو ستوديو",
  "melie bianco": "ميلي بيانكو",
  imoshion: "ايموسيون",
  "mms trading": "ممس ترادنج",
  roxy: "روكسي",
  "robert zur": "روبرت زور",
  "bacco bucci": "باكو بوتشي",
  oomphies: "اومفيز",
  "see kai run": "سي كاي رن",
  "hot shoot": "هوت شوت",
  "giulia n": "جوليا أن",
  smyk: "إس مك",
  "lelli kelly": "ليلي كيلي",
  "mark nason ": "مارك نيسون",
  "asics tiger": "أسيكس تايجر",
  "tyler rodan": "تايلر رودان",
  mytagalongs: "ماي تاغ ألونجز",
  "foley corinna": "فولي كورينا",
  "civico 10": "سيفيكو ١٠",
  "tshirt jeans": "تي شيرت جينز",
  werner: "ويرنر",
  "modern fiction   ": "مودرن فكشن",
  "mia girl  ": "ميا جيرل",
  "annie ": "آني",
  "  j/slides     ": " جيه سلايدز",
  "b-52 by bullboxer   ": "بي ـ٥٢ باي بول بوكسر",
  "b.o.c. born concept      ": "بي ئو سي بورن كونسبت",
  "m.a.p.           ": "ام ايه بي ",
  "baretraps ": "بيرتربس",
  "lemon ": "ليمون",
  "rock & candy by zigi   ": "روك و كاندي باي زينجي",
  "rampage ": "رام بيج ",
  "madden girl   ": "مادن جيرل",
  ellesse: "إل إس",
  "michael antonio": "مايكل انطونيو",
  kenkoh: "كينكو",
  "sneaker lab": "سنيكر لاب",
  "massimo santini": "ماسيمو سانتيني",
  zaha: "زها",
  "paola fiorenza": "باولا فيورينزا",
  "versace jeans": "فيرساتشي جينز",
  "essex lane": "اسيكس لين",
  "max + jake": "ماكس + جيك",
  restricted: "ريستريكتد",
  "stride rite": "سترايد رايت",
  flexus: "فليكسس",
  "crep protect": "كريب بروتكت",
  "berastogi ": "بيراستوجي",
  ccc: "سي سي سي",
  "j/slides": "جي سلايدز",
  "studio isola": "ستوديو ايسولا",
  "moda luxe": "مودا لوكس",
  lanetti: "لانيتي ",
  sprandi: "سبراندي",
  "jenny fairy": "جيني فيري",
  "clara barson": "كلارا بارسون",
  lasocki: "لاسوكي",
  "nylon red": "نايلون ريد",
  "nelli blu": "نيلي بلو",
  ottimo: "أوتيمو",
  fitflop: "فت فلوب",
  "aldo kids": "ألدو كيدز",
  "gino lanetti": "جينو لانيتي",
  "magic lady": "ماجيك ليدي",
  vapiano: "فابيانو",
  "action boy": "آكشن بوي",
  "zahra abayas": "عبايات زهرة",
  "sara arabia": "سارة العربية",
  inblu: "إنبلو",
  collonil: "كولونيل",
  daylo: "دايلو",
  mignas: "المقناص ",
  syria: "سيريا",
  "own the looks": "أون ذا لوكس",
  kashkha: "كَشخة",
  "brave soul": "برايف سول",
  papilio: "بيبيليو",
  coccine: "كوكين",
  walky: "والكي",
  "via ravia ": "فيا رافيا ",
  "prive revaux ": "بريف ريفو",
  bassano: "باسانو",
  deezee: "ديزي",
  skarpety: "سكاربيتي",
  "loreal ": "لوريال",
  multiplex: "ملتيبليكس",
  "prive revaux": "بريف ريفو",
  "geox ": "جيوكس",
  "dr. teal's": "دكتور تيلز",
  "absolute new york": "آبسلوت نيويورك",
  "pretty animalz": "بريتي أنيمالز",
  "doll face": "دول فيس",
  flormar: "فلورمار",
  "swiss image": "سويس إيميج",
  "wet n wild": "ويت آند وايلد",
  "l'oreal paris": "لوريال باريس",
  garnier: "غارنييه",
  hema: "هيما",
  geox: "جيوكس",
  citron: "سيترون",
  b180: "بي 180",
  "paski @ ccc": "باسكي سي سي سي",
  "talking tables": "توكينج تيبلز",
  "home&relax": "هوم آند ريلاكس",
  camelbak: "كامل باك",
  "meri meri ": "ميري ميري",
  "smily kiddos ": "سمايلي كيدوس",
  "6thstreet": "6th ستريت",
  "tommy jeans": "تومي جينز",
  fila: "فيلا",
  eastpak: "إيست باك",
  arshys: "آرشيز",
  bodyboom: "بودي بوم",
  "pinky goat": "بينكي غوت ",
  "physicians formula": "فيزشنز فورميولا",
  "bubble t": "بابل تي",
  "mad hippie": "ماد هيبي",
  solinotes: "سولينوتس",
  "lottie london": "لوتي لندن",
  "jack & jones": "جاك آند جونز",
  kocostar: "كوكو ستار",
  "dune accessories": "ديون اكسسوريز",
  "max factor": "ماكس فاكتور",
  bentley: "بنتلي ",
  boucheron: "بوشرون",
  "britney spears": "بريتني سبيرز",
  burberry: "بربري",
  cabotine: "كابوتين",
  chanel: "شانيل",
  chloe: "كلوي ",
  chopard: "شوبارد ",
  clinique: "كلينك",
  "david beckham": "ديفيد بيكهام",
  "dunhill dunhill ": "دانهيل",
  "elie saab": "إيلي صعب",
  "elizabeth arden": "إليزابيث أردن",
  "jessica simpson": "جيسيكا سيمبسون",
  "jimmy choo ": "جيمي شو",
  lancome: "لانكوم",
  "mancera ": "مانسيرا",
  "marc jacobs": "مارك جاكوبس",
  missoni: "ميسوني",
  "mont blanc": "مون بلان",
  moschino: "موسكينو",
  "paco rabanne": "باكو رابان",
  "paris hilton ": "باريس هيلتون",
  pitbull: "بيتبول",
  rihanna: "ريهانا",
  "roberto cavalli": "روبرتو كافالي",
  "salvatore ferragamo": "سلفادور فيراغامو",
  "sofia vergara": "صوفيا فيرجاراصوفيا فيرجارا",
  "tom ford": "توم فورد",
  "tommy bahama": "تومي باهاما",
  "united colors of benetton": "يونايتد كلرز اوف بينيتون",
  "vince camuto": "فينس كاموتو",
  "rimmel london ": "ريميل لندن",
  "barber club": "باربر كلوب ",
  "maybelline new york": "مايبلين نيو يورك",
  aerosoles: "إيروسولز",
  kipling: "كيبلينج",
  "petite maison ": "بيتي ميشن",
  "estee lauder": "استي لودر",
  iceberg: "ايسبيرج",
  "philip kingsley": "فيليب كينغيسلي",
  "urban veda": "إربان فيدا",
  "hawkins and brimble": "هاوكينز أند بريمبل ",
  "noisy may": "نويزي ماي",
  "27edit": "27 ايديت",
  moistreet: "جيس",
  "the original knit": "ذا اورجنال كيت",
  bousni: "بوسني",
  "carter's": "كارترز",
  oshkosh: "أوشكوش",
  lakeland: "لايكلاند",
  "okaidi obaibi": "اوكايدي اوبايبي",
  undiz: "اونديز",
  "bright spring": "برايت سبرينج",
  dickie: "ديكي",
  smoby: "اسموبي",
  "sergio tacchini": "سيرغيو تاكتشيني",
  "fischer price": "فيشر برايس",
  "justice league": "جاستس ليج - فرقة العدالة",
  enchantimals: "إنشانتيمالز",
  "shimmer & shine": "شيمر اند شاين",
  givenchy: "جيفنشي",
  cartier: "كارتير",
  lalique: "لاليك",
  robertino: "روبيرتينو",
  lucianno: "لوشيانو",
  "deezee @ ccc": "ديزي سي سي سي",
  "roza abaya": "روزا عباية",
  playmobil: "بلاي موبيل",
  "my toys": "ماي تويز",
  tinkerbell: "تيكنربيل",
  "rescue bots": "ريسكيو بوتس",
  nerf: "نيرف",
  "bath toy": "باث توي",
  parfois: "بارفويز",
  "many frock": "ماني فروك",
  smiki: "سمايك",
  trolls: "ترولز",
  "alex toys": "العاب اليكس",
  "buzzbee toys": "باز بي تويز",
  "keel toys": "كيل تويز",
  motormax: "موتورماكس",
  playgo: "بلاي جو",
  "plum play": "بلام بلاي",
  "plum collection": "بلام كوليشكن",
  rastar: "راستار",
  simplay3: "سيم بلاي3",
  tcg: "تي سي جي",
  "sugar rush": "شوجار راش",
  vincci: "فينتشي",
  jaguar: "جاغوار",
  ysl: "واي اس ال",
  "berastogi @ ccc": "بيراستوجي سي سي سي",
  "yves rocher": "يفيس روتشر",
  merlin: "ميرلين",
  "zaha @ ccc": "زاها سي سي سي",
  "banana republic": "بانانا ريبابليك",
  "elizabeth taylor": "اليزابيث تايلور",
  "s.t. dupont": "اس تي دوبونت",
  nupa: "نوبا",
  ungaro: "اونجارو",
  "victoria secret": "فيكتوريا سيكريت",
  armani: "أرماني",
  fleur: "فلور",
  "accessories @ ccc": "اكسسوارات @ سي سي سي",
  "shoe acc @ ccc": "إكسسوارات الأحذية @ سي سي سي",
  "socks @ ccc": "جوارب @ سي سي سي",
  "filmar @ ccc": "فيلمار سي سي سي",
  "coccine @ ccc": "كوكيين سي سي سي",
  "essential fragrance": "ايسنثيال فراجرانس",
  "the smash room": "ذا سماش روم",
  jenga: "جينجا",
  "connect 4": "كونيكت 4",
  "speak out": "سبيك اوت",
  "trivial pursuit": "تريفال بورشيت",
  monopoly: "مونوبولي",
  "play-doh": "بلاي دي دي اتش",
  "baby alive": "بيبي الايف",
  "spider-man": "سبايدر مان",
  "furreal friends": "فيورال فريندز",
  "disney princess": "اميرات ديزني",
  "rimmel london": "ريميل لندن",
  "pinky goat ": "بينكي جوت",
  tosara: "توسارا",
  "jenny fairy @ ccc": "جيني فيري سي سي سي",
  "ng perfumes": "إن جي بيرفيوم",
  jacadi: "جاكادي",
  "austin reed @ ccc": "اوستن رييد سي سي سي",
  "disney @ ccc": "ديزني سي سي سي",
  silhoutte: "سيلهوت",
  amethyst: "اميثيست",
  "ottimo @ ccc": "اوتيمو سي سي سي",
  "lasocki @ ccc": "لاسوسكي سي سي سي",
  "sprandi @ ccc": "سبراندي سي سي سي",
  "nelli blu @ ccc": "نيلي بلو سي سي سي",
  "gino lanetti @ ccc": "جينو لانيتي سي سي سي",
  "lanetti @ ccc": "لانيتي سي سي سي",
  "nylon red @ ccc": "نيلون ريد سي سي سي",
  "clara barson @ ccc": "كلارا بارسون سي سي سي",
  "action boy @ ccc": "اكشن بوي سي سي سي",
  "magic lady @ ccc": "ماجيك لايدي سي سي سي",
  "inblu @ ccc": "انبلو سي سي سي",
  "bassano @ ccc": "باسانو سي سي سي",
  "mb @ ccc": "ام بي سي سي سي",
  "walky @ ccc": "ووكي سي سي سي",
  "via ravia @ ccc": "فيا رافي سي سي سي",
  "go soft @ ccc": "جو سوفت سي سي سي",
  "home&relax @ ccc": "هوم ريلاكس",
  "gino rossi @ ccc": "جينو روسي سي سي سي",
  "tranquil charm": "ترانقول شرم",
  "festive feels": "فيستيفال فيلز",
  "little things": "ليتل ثينجس-اشياء صغيرة",
  "essentially yours": "ايسينشيالي يورز",
  "essentials for her": "اساسا لها",
  "family gift set": "فاميلي جيفت سيت",
  "shine bright": "شاين برايت",
  "pretty as pink": "بريتي از بينك",
  "it's all peachy!": "اتس اول بيتشي",
  "brunch on the beach": "برانش اون ذا بيتش",
  "midnight glamour": "ميد نايت جلامور",
  "let's get tech!": "ليتس جيت تيك",
  "doll's day out": "دولس داي اوت",
  "ready for the sunset": "ريدي فور ذا سنن",
  "out & about": "اوت اند ابوت",
  "sunny days": "صني دايز",
  "two is better than one": "تو اذ بيتر ذان وان",
  "cupcake haven": "كوب كيك هافين",
  "fantasy bakes": "فانتاسي باكيس",
  "mama mia": "ماما ميا",
  "skincare rituals": "سكين كير ريتوالز",
  "eid glow up": "ايد جلو اب",
  "mani moment": "ماني مومينت",
  "all time favorites": "المفضل في كل وقت",
  "desert rose": "دييزيرت روز",
  "girl power": "جيرل باور",
  "metallic glam": "ماتيلك جلام",
  "down to earth": "داون تو ايرث",
  "for the love of white": "فور ذا لوف اوف وايت",
  "the wander-woman": "ذا وندر ومنات",
  "pastel hues": "باستيل هوز",
  "tommy girl!": "تومي جيرل",
  "new kid on the block": "نيو كيد اون ذا بلوك",
  "shades of blue": "شادز اوف بلو",
  "monochrome ready": "مونوكروم ريدي",
  "all black everything!": "كل شيئ اسود",
  "stay on point": "استاي اون بوينت",
  "diva things": "ديفا ثينجس",
  "sparkle this summer": "سباركل ذيذ سمر",
  "shine on": "شاين اون",
  "unicorn dream": "يوني كورن دريم",
  "gamer mode": "جامر مود",
  "always on the go": "دائما اثناء التنقل",
  "cherry bomb": "تشيري بومب",
  "calvin klein kids": "كالفن كلاين كيدز",
  mikyajy: "مكياجي",
  "air val": "إير فال",
  "oh so heavenly": "أوه سو هيفنلي",
  xcluzive: "اكسكلوزيف",
  "tommy hilfiger kids": "تومي هيلفيغر كيدز",
  "chrixtina rocca": "كريستينا روكا ",
  "ajmal perfume ": "أجمل",
  "pretty by flormar": "بريتي باي فلورمار",
  sapil: "سابيل",
  adyan: "أديان",
  mural: "مورال",
  "english blazer": "إنجليش بليزر",
  "zoya collection": "زويا كولكشن",
  "dales and dunes": "دالاس اند ديونز",
  "french factor": "فرنش فاكتور",
  enity: "انتيتي",
  "rich & ruitz": "ريتش آند روتز",
  hamlet: "هاملت",
  lili: "ليلي ",
  dumont: "ديمونت",
  marshall: "مارشال",
  "ulric de varens": "أولريك دي فارينز",
  police: "بوليس",
  "colour me": "كولور مي",
  "abercrombie fitch": "ابيركومبي اند فيتش",
  "air val intl": "إير فال",
  "corine de farme": "Corine de farme",
  cottage: "COTTAGE",
  dayheels: "Dayheels",
  "elegant touch": "ELEGANT TOUCH",
  enfresh: "Enfresh",
  eylure: "إيلور",
  "farm skin": "Farm Skin",
  firefly: "Firefly",
  hask: "HASK",
  "i love": "I Love",
  "lip smacker": "لبس ماكر",
  "masque bar": "MASQUE BAR",
  milani: "MILANI",
  "oscar de la renta": "أوسكار دي لارنتا",
  "pearlie white": "Pearlie White",
  "physician formula": "فيزشنز فورميولا",
  pielor: "بيلر",
  pretty: "بريتي باي فلورمار",
  safi: "صافي",
  "sally hansen": "Sally Hansen",
  streax: "Streax",
  "humble & co.": "همبل اند كو",
  "thicker fuller hair": "ثيكر فولير هير",
  trichup: "تريشوب",
  zylist: "زيلست",
  "mark nason": "مارك نيسون",
  "modern fiction": "مودرن فكشن",
  "mia girl": "ميا جيرل",
  annie: "آني",
  "b-52 by bullboxer": "بي ـ٥٢ باي بول بوكسر",
  "b.o.c. born concept": "بي ئو سي بورن كونسبت",
  "m.a.p.": "ام ايه بي",
  baretraps: "بيرتربس",
  lemon: "ليمون",
  "rock & candy by zigi": "روك و كاندي باي زينجي",
  rampage: "رام بيج",
  "madden girl": "مادن جيرل",
  "via ravia": "فيا رافيا",
  loreal: "لوريال",
  "meri meri": "ميري ميري",
  "smily kiddos": "سمايلي كيدوس",
  "dunhill dunhill": "دانهيل",
  mancera: "مانسيرا",
  "petite maison": "بيتي ميشن",
  "ajmal perfume": " أجمل برفم",
  ajmal: "Ajmal",
  "david off": "David Off",
  "erre due": "Erre Due",
  "lanvin paris": "Lanvin Paris",
  "boucheron paris": "بوشرون",
  "logos-coach": "Logos-coach",
  "karl lagerfeld": "Karl Lagerfeld",
  "logos- lanvin": "Logos- Lanvin",
  modbeautykeeper: "ModBeautyKeeper",
  "oscar de larenta": "أوسكار دي لارنتا",
  routine: "روتين",
  "a & f": "A & F",
  niran: "Niran",
  davidoff: "DAVIDOFF",
  "dunhill london": "دنهل",
  "abercrombie & fitch": "ابيركومبي اند فيتش ",
  f5: "F5",
  "fila heritage": "فيلا حريتج",
  ayra: "Ayra",
  "cool club @ smyk": "Cool Club @ SMYK",
  "some by mi": "MIبعض من ",
  "sigma beauty": "سيجما بيوتي",
  girlactik: "جيرلاكتيك",
  "the crème shop": "متجر Crème",
  "voce haircare": "عنصر العناية بالشعر",
  "hank & henry": "هانك وهنري",
  "smiki @ smyk": "SMIKI @ SMYK",
  swims: "SWIMS",
  schutz: "Schutz",
  tamima: "Tamima",
  kybun: "Kybun",
  callaghan: "CallagHan",
  clarks: "Clarks",
  beardo: "BEARDO",
  alboom: "Alboom",
  "amal ibrahim": "أمل إبراهيم",
  souna:"سونا",
};

export default BRAND_MAPPING;
