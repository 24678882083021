import Event, { EVENT_PDP_OFFER_TITE_SELECTED } from "Util/Event";
import BaseEvent from "./Base.event";
export const SPAM_PROTECTION_DELAY = 200;

class PdpOffersTileSelected extends BaseEvent {
  bindEvent() {
    Event.observer(EVENT_PDP_OFFER_TITE_SELECTED, ({ brand_name ,product_sku, tab_name }) => {
      this.handle({
        brand_name,
        product_sku,
        tab_name
      });
    });
  }

  handler({ brand_name, product_sku, tab_name }) {
    if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
      return;
    }

    this.pushEventData({
      brand_name,
      product_sku,
      tab_name
    });
  }
}

export default PdpOffersTileSelected;
