import Event, { EVENT_PDP_VIEW_PROMOTION, EVENT_PDP_SELECT_PROMOTION } from "Util/Event";
import BaseEvent from "./Base.event";
export const SPAM_PROTECTION_DELAY = 200;

class ViewPromotionEvent extends BaseEvent {
  bindEvent() {
    Event.observer(EVENT_PDP_VIEW_PROMOTION, ({ brand_name ='' ,product_sku ='',promotion_id, promotion_name, items }) => {
      this.handle({
        brand_name,
        product_sku,
        promotion_id,
        promotion_name,
        items
      });
    });
  }
 
  handler({ brand_name = "", product_sku = "", promotion_id="",promotion_name="",items=[] }) {
    this.pushEventData({
      brand_name : brand_name ? brand_name : '',
      product_sku : product_sku ? product_sku :'',
      promotion_id:promotion_id ? promotion_id : '',
      promotion_name: promotion_name ? promotion_name :'',
      ecommerce:{
        items:[...items] 
      }
    });
  }
}

export default ViewPromotionEvent;
