/* eslint-disable max-len */
import PropTypes from "prop-types";
import { PureComponent } from "react";

import CountryMiniFlag from "Component/CountryMiniFlag";
import Field from "Component/Field";
import Form from "Component/Form";
import {
  COUNTRY_CODES_FOR_PHONE_VALIDATION,
  PHONE_CODES,
} from "Component/MyAccountAddressForm/MyAccountAddressForm.config";
import Loader from "SourceComponent/Loader";
import Popup from "SourceComponent/Popup";
import { isArabic } from "Util/App";
import isMobile from "Util/Mobile";
import Image from "Component/Image";
import CALogo from "./icons/CA-logo.png";
import {
  STATE_LINK,
  STATE_SUCCESS,
  STATE_VERIFY,
  STATE_DE_LINK,
  STATE_DE_LINK_SUCCESS,
  STATE_AUTHENTICATION,
  STATE_CONFRIM_EXISTING,
  STATE_PURPOSE_LINK,
  STATE_PURPOSE_CHANGE,
} from "./MyAccountClubApparelOverlay.config";
import SuccessImage from "./icons/successful_tick.gif";
import DeLinkSuccess from "./icons/DeLinkSuccess.svg";
import "./MyAccountClubApparelOverlay.style";

class MyAccountClubApparelOverlay extends PureComponent {
  static propTypes = {
    hideActiveOverlay: PropTypes.func.isRequired,
    linkAccount: PropTypes.func.isRequired,
    state: PropTypes.string.isRequired,
    verifyOtp: PropTypes.func.isRequired,
    phone: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    country: PropTypes.string.isRequired,
    handleCreateAccount: PropTypes.func.isRequired,
  };

  state = {
    isArabic: isArabic(),
    isButtonDisabled: true,
    phoneValue: [],
    otpValue: "",
    otpFocused: false,
  };

  renderMap = {
    [STATE_LINK]: {
      render: () => this.renderLink(),
    },
    [STATE_VERIFY]: {
      render: () => this.renderVerify(),
    },
    [STATE_SUCCESS]: {
      render: () => this.renderSuccess(),
    },
    [STATE_DE_LINK]: {
      render: () => this.renderDeLink(),
    },
    [STATE_DE_LINK_SUCCESS] :{
      render: () => this.renderdeLinkSuccess(),
    },
    [STATE_AUTHENTICATION]: {
      render: () => this.renderAuthentication(),
    },
    [STATE_CONFRIM_EXISTING]: {
      render: () => this.renderConfrimExistingAcc(),
    }
  };

  constructor(props) {
    super(props);

    const { country } = props;

    this.state = {
      selectedCountry: country,
      isArabic: isArabic(),
      isProceedDisabled: true,
      isButtonDisabled: true,
      phoneValue: [],
      otpValue: "",
      otpFocused: false,
    };
  }
  componentDidMount() {
    const { customer: { phone} } = this.props;
    const phoneCode = phone?.slice(0,4);
    const countries = Object.keys(PHONE_CODES);

    const countiresMapped = countries.reduce((acc, country) => {
      if (phoneCode === PHONE_CODES[country]) {
        acc.push(country);
      }

      return acc;
    }, []);

    this.setState({ selectedCountry: countiresMapped[0] });
  }

  componentDidUpdate() {
    const { error } = this.props;
    const { otpFocused } = this.state;
    if (error && !otpFocused) {
      this.setState({ otpValue: "" });
    }
  }

  setLinkStateOrAutoLink(){
    const { customer: { custom_attributes : { is_mobile_otp_verified }}, setLinkState, autoEnrollCustomer, linkOrChangeCaAcc} = this.props;
    console.log("test is_mobile_otp_verified", is_mobile_otp_verified);
    if(is_mobile_otp_verified && linkOrChangeCaAcc == STATE_PURPOSE_LINK){
      autoEnrollCustomer();
    } else if((!is_mobile_otp_verified && linkOrChangeCaAcc == STATE_PURPOSE_LINK) || (linkOrChangeCaAcc == STATE_PURPOSE_CHANGE)){
      setLinkState();
    } else if(is_mobile_otp_verified){
      autoEnrollCustomer();
    } else if(!is_mobile_otp_verified){
      setLinkState();
    }
  }

  renderAuthentication() {
    const { hideActiveOverlay, setLinkState, handleCreateAccount, clubApparel } = this.props;
    const { isArabic } = this.state;
   // this can be uncommented later if req based on use case
    // if (clubApparel?.accountLinked) {
    //   return null;
    // }
    return (
      <div
        block="MyAccountClubApparelOverlay"
        elem="Confirmation"
        mods={{ isArabic }}
      >
        <h4>{__("Create new account")}</h4>
        <p>
          {__(
            "Do you want to create your new account now?"
          )}
        </p>
        <div block="MyAccountClubApparelOverlay" elem="ButtonContainer">
          <div block="MyAccountClubApparelOverlay" elem="Decline">
            <button
              block="MyAccountClubApparelOverlay"
              elem="DeclineButton"
              onClick={hideActiveOverlay}
            >
              {__("No")}
            </button>
          </div>
          <div block="MyAccountClubApparelOverlay" elem="Confirm">
            <button
              block="MyAccountClubApparelOverlay"
              elem="ConfirmButton"
              onClick={ () => {this.setLinkStateOrAutoLink();}}
            >
              {__("Yes")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderDeLink() {
    const { hideActiveOverlay, handleDeLinkAccount, clubApparel } = this.props;
    const { isArabic } = this.state;
   
   // this condition will be added on button not here
    // if (clubApparel?.accountLinked) {
    //   return null;
    // }
    return (
      <div
        block="MyAccountClubApparelOverlay"
        elem="Confirmation"
        mods={{ isArabic }}
      >
        <h4>{__("De-link Club Apparel account")}</h4>
        <p>
          {__(
            "Are you sure you want to de-link your account?"
          )}
        </p>
        <div block="MyAccountClubApparelOverlay" elem="ButtonContainer">
          <div block="MyAccountClubApparelOverlay" elem="Decline">
            <button
              block="MyAccountClubApparelOverlay"
              elem="DeclineButton"
              onClick={hideActiveOverlay}
            >
              {__("cancel")}
            </button>
          </div>
          <div block="MyAccountClubApparelOverlay" elem="Confirm">
            <button
              block="MyAccountClubApparelOverlay"
              elem="ConfirmButton"
              onClick={handleDeLinkAccount}
            >
              {__("confirm de-link")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  handleVerifyChange = (e = []) => {
    // eslint-disable-next-line no-magic-numbers
    this.setState({
      otpFocused: true,
      isButtonDisabled: e.length !== 5,
      otpValue: e,
    });
  };

  renderCurrentPhoneCode(country_id) {
    return PHONE_CODES[country_id];
  }

  handleSelectChange = (e) => {
    const countries = Object.keys(PHONE_CODES);

    const countiresMapped = countries.reduce((acc, country) => {
      if (e === this.renderCurrentPhoneCode(country)) {
        acc.push(country);
      }

      return acc;
    }, []);

    this.setState({ selectedCountry: countiresMapped[0], phoneValue: [] });
  };

  handlePhoneNumberChange(e) {
    const { selectedCountry } = this.state;
    const maxLength = COUNTRY_CODES_FOR_PHONE_VALIDATION[selectedCountry]
      ? "9"
      : "8";
    if (e.length == maxLength) {
      this.setState({ isProceedDisabled: false });
    } else {
      this.setState({ isProceedDisabled: true });
    }
  }

  renderOption = (country) => ({
    id: country,
    label: this.renderCurrentPhoneCode(country),
    value: this.renderCurrentPhoneCode(country),
  });
  isNumber = (evt) => {
    const regex = /[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    const regexOnlyDigits = /^\d+$/;
    const invalidChars = ["-", "+", "e", "E", "."];
    if (invalidChars.includes(evt.key)  || regex.test(evt.key) || !regexOnlyDigits.test(evt.key) ) {
      evt.preventDefault();
      return false;
    }
  };

  renderPhone() {
    const { customer: { phone} } = this.props;
    const { selectedCountry, isArabic, phoneValue } = this.state;
    const countries = Object.keys(PHONE_CODES);

    if(!selectedCountry){
      const phoneCode = phone?.slice(0,4);
      const countiresMapped = countries.reduce((acc, country) => {
        if (phoneCode === PHONE_CODES[country]) {
          acc.push(country);
        }
        return acc;
      }, []);
      this.setState({ selectedCountry: countiresMapped[0] });
    }

    const maxlength = COUNTRY_CODES_FOR_PHONE_VALIDATION[selectedCountry]
      ? "9"
      : "8";
    const userValidation = COUNTRY_CODES_FOR_PHONE_VALIDATION[selectedCountry]
      ? "telephoneAE"
      : "telephone";

    return (
      <div
        block="MyAccountClubApparelOverlay"
        elem="LinkAccountPhone"
        mods={{ isArabic }}
      >
        <Field
            mix={{
              block: "MyAccountClubApparelOverlay",
              elem: "LinkAccountPhoneCode",
            }}
          type="select"
          id="countryPhoneCode"
          name="countryPhoneCode"
          onChange={this.handleSelectChange}
          selectOptions={countries.map(this.renderOption)}
          value={PHONE_CODES[selectedCountry]}
        />
        <Field
          mix={{
            block: "MyAccountClubApparelOverlay",
            elem: "LinkAccountPhoneField",
          }}
          validation={["notEmpty", "number", userValidation]}
          placeholder={__("Phone Number")}
          maxlength={maxlength}
          pattern="[0-9]*"
          value={phone?.slice(4)}
          id="phone"
          name="phone"
          onChange={(e) => this.handlePhoneNumberChange(e)}
          onKeyPress={(e) => this.isNumber(e)}
        />
        <CountryMiniFlag mods={{ isArabic }} label={selectedCountry} />
      </div>
    );
  }

  renderSuccess() {
    const { hideActiveOverlay } = this.props;
    const { isArabic } = this.state;

    return (
      <div
        block="MyAccountClubApparelOverlay"
        elem="Success"
        mods={{ isArabic }}
      >
        <h4>{__("Linking Successful!")}</h4>
        <p>
          {__(
            "You've now successfully linked your account with us. Enjoy this rewarding journey today!"
          )}
        </p>
        <button
          block="MyAccountClubApparelOverlay"
          elem="LinkAccountButton continue"
          onClick={hideActiveOverlay}
        >
          {__("START SHOPPING NOW")}
        </button>
      </div>
    );
  }

  renderdeLinkSuccess() {
    const { isArabic } = this.state;

    return (
      <div
        block="MyAccountClubApparelOverlay"
        elem="DeLinkSuccess"
        mods={{ isArabic }}
      >
        <h4>{__("We are sad to see you go!")}</h4>
        <p>
          {__(
            "Your Club Apparel account has been de-linked"
          )}
        </p>
      </div>
    );
  }

  renderLink() {
    const { linkAccount, handleCreateAccount } = this.props;
    const { isProceedDisabled } = this.state;
    return (
      <>
        <p>
          {__(
            "Enter your mobile number for linking your account with Club Apparel"
          )}
        </p>

        <Form onSubmitSuccess={handleCreateAccount}>
          {this.renderPhone()}
          <div block="MyAccountClubApparelOverlay" elem="Btn_Container">
            <button
              block="MyAccountClubApparelOverlay"
              elem="LinkAccountButton"
              type="submit"
              // disabled={isProceedDisabled}  // can be uncommented later if required 
              // onClick={handleCreateAccount}
            >
              {__("PROCEED")}
            </button>
          </div>
        </Form>
      </>
    );
  }

  onResendButtonClick() {
    const { linkAccount, phone } = this.props;
    const fields = { phone: phone.replace("00", ""), countryPhoneCode: "" };
    linkAccount(fields);
    this.setState({ otpValue: "" });
  }

  renderVerify() {
    const {
      verifyOtp,
      error,
      setLinkState,
      isResendDisabled,
      customerPhone,
    } = this.props;
    const { isButtonDisabled, otpValue, isArabic } = this.state;

    return (
      <div
        block="MyAccountClubApparelOverlay"
        elem="Verify"
        mods={{ isArabic }}
      >
        <h4>{__("Verify your mobile number")}</h4>
        <p className="PhoneNumber">
          {__("Enter the OTP code sent to ")} {" "} {customerPhone}
        </p>
        <Form onSubmitSuccess={verifyOtp}>
          <Field
            type="text"
            id="otp"
            name="otp"
            placeholder=""
            pattern="[0-9]*"
            onChange={this.handleVerifyChange}
            validation={["notEmpty"]}
            maxlength="5"
            value={otpValue}
            onKeyPress={(e) => this.isNumber(e)}
          />
          <div block="resendOTP" elem="Container" mods={{ isResendDisabled }}>
            {error ? (
              <p
                block="MyAccountClubApparelOverlay"
                elem="NotSuccessParagraphRed"
              >
                <span></span>
                {__("Wrong OTP entered")}
              </p>
            ) : (
              <p>{__("Didn't receive OTP?")}</p>
            )}
            <button
              block="MyAccountClubApparelOverlay"
              elem="VerifyResend"
              type="button"
              disabled={isResendDisabled}
              onClick={() => {
                this.onResendButtonClick();
              }}
            >
              {__("Resend OTP")}
            </button>
          </div>
          <div block="MyAccountClubApparelOverlay" elem="Btn_Container">
            <button
              block="MyAccountClubApparelOverlay"
              elem="ChangeNumberBtn"
              type="button"
              onClick={() => {
                this.setState({ otpValue: "" });
                setLinkState();
              }}
            >
              {__("CHANGE NUMBER")}
            </button>
            <button
              block="MyAccountClubApparelOverlay"
              elem="VerifyButton"
              type="submit"
              disabled={isButtonDisabled}
            >
              {__("VERIFY AND PROCEED")}
            </button>
          </div>
        </Form>
      </div>
    );
  }

  renderConfrimExistingAcc(){
    const {
      customer: { phone},
      setLinkState,
      customerPhone,
      autoEnrollCustomer,
      hideActiveOverlay,
    } = this.props;
    const { isArabic } = this.state;

    return (
      <div
        block="MyAccountClubApparelOverlay"
        elem="Verify"
        mods={{ isArabic }}
      >
        <h4>{__("Dear Customer,")}</h4>
        <p>
          {__("We see a Club Apparel account associated with")} 
        </p>
        <h4 className="PhoneNumber">{phone}</h4>
        <p>
          {__(`To earn Club Apparel points on 6th Street online, link your account now!`)} 
        </p>
          <div block="MyAccountClubApparelOverlay" elem="Btn_Container">
            <button
              block="MyAccountClubApparelOverlay"
              elem="ChangeNumberBtn"
              type="button"
              onClick={() => {
                this.setState({ otpValue: "" });
                setLinkState();
              }}
            >
              {__("NOT MY ACCOUNT")}
            </button>
            <button
              block="MyAccountClubApparelOverlay"
              elem="VerifyButton"
              type="submit"
              onClick={() => autoEnrollCustomer()}
            >
              {__("LINK MY CLUB APPAREL ACCOUNT")}
            </button>
          </div>
      </div>
    );
  }

  renderOverlay() {
    const { isArabic } = this.state;
    const { state, isLoading } = this.props;
    const { render } = this.renderMap[state];
    const isMessage = state == STATE_AUTHENTICATION || state == STATE_SUCCESS || state == STATE_DE_LINK || state == STATE_DE_LINK_SUCCESS;
    const isMobileValue = isMobile.any();
    return (
      <>
        <Popup
          mix={{
            block: "MyAccountClubApparelOverlay",
            mods: { isArabic, isMessage },
          }}
          id="LinkAccount"
          title="Link"
          clickOutside={ false }
        >
          {isLoading ? <Loader isLoading={isLoading} /> : null}
          <div
            mix={{
              block: "MyAccountClubApparelOverlay",
              elem: "LinkAccountBanner",
              mods: { state },
            }}
          >
            {state == STATE_SUCCESS ? (
              <Image
                lazyLoad={true}
                className="LinkAccountSuccess"
                src={SuccessImage}
                alt="Logo icon"
              />
            ) : (
              <Image
                lazyLoad={true}
                className="LinkAccountLogo"
                src={state == STATE_DE_LINK_SUCCESS ? DeLinkSuccess: CALogo}
                alt="Logo icon"
              />
            )}
          </div>
          {render()}
        </Popup>
      </>
    );
  }

  render() {
    return (
      <div block="MyAccountClubApparelOverlay">{this.renderOverlay()}</div>
    );
  }
}

export default MyAccountClubApparelOverlay;
