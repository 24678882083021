import Event, { EVENT_GTM_FOOTER } from "Util/Event";
import BaseEvent from "./Base.event";

/**
 * Website places, from where was received event data
 *
 * @type {string}
 */

/**
 * Constants
 *
 * @type {number}
 */
export const SPAM_PROTECTION_DELAY = 200;
export const EVENT_HANDLE_DELAY = 700;
export const URL_REWRITE = "url-rewrite";
/**
 * GTM PWA Impression Event
 *
 * Called when customer see banners on home page
 */
class FooterEvent extends BaseEvent {
  /**
   * Set base event call delay
   *
   * @type {number}
   */
  eventHandleDelay = EVENT_HANDLE_DELAY;

  /**
   * Bind PWA event handling
   */
  bindEvent() {
    Event.observer(EVENT_GTM_FOOTER, (data) => {
      this.handle(data);
    });
  }

  handler(data) {
    const eventCategory = data.includes("follow")
      ? "social_media_icon"
      : "footer_tracking";
    this.pushEventData({
      event: data,
      eventCategory: eventCategory,
      eventAction: (data == "feedback_form_submit") ? "feedback_form_submitted" : data,
      UserType:
        this.getCustomerId().toString().length > 0 ? "Logged In" : "Logged Out",
    });
  }
  
  getCustomerId() {
    return this.isSignedIn()
      ? this.getAppState().MyAccountReducer.customer.id || ""
      : "";
  }

  getPageType() {
    const { urlRewrite, currentRouteName } = window;
    if (currentRouteName === URL_REWRITE) {
      if (typeof urlRewrite === "undefined") {
        return "";
      }
      if (urlRewrite.notFound) {
        return "notfound";
      }
      return (urlRewrite.type || "").toLowerCase();
    }
    return (currentRouteName || "").toLowerCase();
  }
}

export default FooterEvent;
