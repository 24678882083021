import MobileAPI from "../../provider/MobileAPI";

// export const getCartTotals = (cartId) =>
//   MobileAPI.get(`/carts2/${cartId}/totals`) || {};

// ("/generatecoupon?locale=en-ae");

export const generateCoupon = ({ cartId, userId }) =>
  MobileAPI.post(`/generatecoupon`, {
    quote_id: cartId,
    // userID also needed
    customer_id: userId,
  }) || {};
