/* eslint-disable import/prefer-default-export */
export const PDP = "product";
export const CATEGORY = "category";
export const CUSTOMER_ACCOUNT = "customer_account";
export const WISHLIST = 'Wishlist';
export const CUSTOMER_SUB_ACCOUNT = "customer_sub_account";
export const CUSTOMER_ACCOUNT_PAGE = "customer_account_page";
export const HOME_PAGE = "homepage";
export const SEARCH = "search";
export const VIEWALL = "view_all";
export const CART = "cart";
export const PICK_UP_ADDRESS = "pick-up-address";
export const CART_OVERLAY = "cart_overlay";
export const SEARCH_OVERLAY = "search-overlay";
export const CART_EDITING = "cart_editing";
export const CHECKOUT = "checkout";
export const CMS_PAGE = "cms_page";
export const BRANDS = "brands";
export const NOT_FOUND = "not_found";
export const URL_REWRITES = "url_rewrites";
export const MENU = "menu";
export const MY_ACCOUNT = "my_account";
export const POPUP = "popup";
export const FEEDBACK = "feedback";
export const LIVE_PARTY = "live-party";
export const ABOUT = "about";
export const STORE = "store";
export const INFLUENCER = "influencer";
export const INFLUENCER_STORE = "store";
export const INFLUENCER_COLLECTION = "collection";
export const MEGAMENU = "megamenu";
export const BRANDSMENU = "brands-menu";
export const EVENTSCALENDAR = "event-calender";
