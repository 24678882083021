import Image from "Component/Image";
import Link from "Component/Link";
import { PureComponent } from "react";
import isMobile from "Util/Mobile";
import background from "./icons/Background.png";
import CALogo from "./icons/CALogo.png";
import mobileBg from "./icons/MobileBG.png";
import "./DynamicContentCaLinkBanner.style.scss";
import Event, { EVENT_GTM_CA_LINK, EVENT_CA_LINKNOW_CLICK } from "Util/Event";
import { setCrossSubdomainCookie } from "Util/Url/Url";
import { getCookie } from "Util/Url/Url";
import { isSignedIn } from "Util/Auth";
import { connect } from "react-redux";
import { toggleOverlayByKey } from "Store/Overlay/Overlay.action";
import PropTypes from "prop-types";
import { setCaStatePostSignup } from "Store/ClubApparel/ClubApparel.action";
import { STATE_AUTHENTICATION } from "Component/MyAccountClubApparelOverlay/MyAccountClubApparelOverlay.config.js"
import MyAccountClubApparelOverlay from "Component/MyAccountClubApparelOverlay";

export const mapStateToProps = (state) => ({
  clubApparel: state.ClubApparelReducer.clubApparel,
  activeOverlay: state.OverlayReducer.activeOverlay,
  caPopupSource: state.ClubApparelReducer.caPopupSource,
});

export const mapDispatchToProps = (dispatch) => ({
  setCaStatePostSignup: (caState, caPopupSource) => dispatch(setCaStatePostSignup(caState, caPopupSource)),
  showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
});

class DynamicContentCABanner extends PureComponent {
  constructor(props) {
    super(props);
  }
  static propTypes = {
    activeOverlay: PropTypes.string.isRequired,
    showOverlay: PropTypes.func.isRequired,
  };

  state = {
    isMobile: isMobile.any() || isMobile.tablet(),
    isSignedIn: isSignedIn(),
    disableBanner: false,
    isPopupOpen: false,
  };

  static getDerivedStateFromProps(props) {
    const { activeOverlay } = props;
    document.body.style.overflow =
      activeOverlay === "LinkAccount" ? "hidden" : "visible";
    return { isPopupOpen: activeOverlay === "LinkAccount" };
  }

  // componentDidMount() {
  //   const { isSignedIn } = this.state;
  //   if (
  //     getCookie("HideCaLinkBanner") === "true" ||
  //     !isSignedIn ||
  //     this.props?.clubApparel?.accountLinked
  //   ) {
  //     this.setState({ disableBanner: true });
  //   } else {
  //     this.setState({ disableBanner: false });
  //   }
  // }

  hideBanner() {
    setCrossSubdomainCookie("HideCaLinkBanner", "true", 7);
    this.setState({ disableBanner: true });
  }

  handleCAModal() {
    const { showOverlay, setCaStatePostSignup } = this.props;
    this.setState({ isPopupOpen: true });
    setCaStatePostSignup(STATE_AUTHENTICATION, "ca-banner");
    showOverlay("LinkAccount");
  }

  renderImages() {
    const { isMobile } = this.state;
    const BgImage = isMobile ? mobileBg : background;
    return (
      <>
        <div className="Banner-Background">
          <Image lazyLoad={true} src={BgImage} alt="ClubApparelLinkBanner" />
        </div>
        <div className="Banner-logo">
          <Image lazyLoad={true} src={CALogo} alt="Club Apparel Logo" />
        </div>
      </>
    );
  }

  renderText() {
    return (
      <>
        <div block="Banner" elem="Content">
          <p>
            {__(
              "Link your account & start earning your Club Apparel points every time you shop"
            )}
          </p>
        </div>
        <div block="Banner" elem="linkNow">
          <button
            type="button"
            block="Button"
            elem="ClubApparelLink"
            onClick={() => {
              this.handleCAModal();
              Event.dispatch(EVENT_GTM_CA_LINK, {
                name: EVENT_CA_LINKNOW_CLICK,
                page: "Homepage",
              });
            }}
          >
            {__("Link now")}
          </button>
        </div>
        <div block="Banner" elem="close">
          <button
            block="Button"
            elem="CAclose"
            onClick={() => this.hideBanner()}
          ></button>
        </div>
      </>
    );
  }

  render() {
    const { caPopupSource } = this.props;
    const { disableBanner, isSignedIn, isPopupOpen } = this.state;
    if (
      !disableBanner &&
      isSignedIn &&
      this.props?.clubApparel?.accountLinked == false
    ) {
      return (
        <>
          <div block="DynamicContentCABanner" id="DynamicContentCABanner">
            {this.renderImages()}
            {this.renderText()}
          </div>
          {isPopupOpen  && caPopupSource === "ca-banner"? <MyAccountClubApparelOverlay popup_state={STATE_AUTHENTICATION}/> : null}
        </>
      );
    } else {
      return null;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicContentCABanner);
