import { getStore } from "Store";
import CartDispatcher from "Store/Cart/Cart.dispatcher";
import { showNotification } from "Store/Notification/Notification.action";

import BrowserDatabase from "Util/BrowserDatabase";

import {
  setModelVisible,
  setModelContent,
  setNudgeVisible,
  setNudgeContent,
  setNudgeHideByUser,
  setIsInstaBuyAvailable,
  setIsTimerRunning,
} from "Store/InstaBuyCoupon/InstaBuyCoupon.action";

import { generateCoupon } from "Util/API/endpoint/InstaBuyCoupon/InstaBuyCoupon.endpoint";

import { isSignedIn } from "Util/Auth";
import Logger from "Util/Logger";

export class InstaBuyCouponDispatcher {
  timer = null;

  async getTimerCoupon(dispatch) {
    const customer = BrowserDatabase.getItem("customer") || {};
    const userId = customer?.id;
    const {
      Cart: { cartId },
    } = getStore().getState();

    const resp = await generateCoupon({ cartId, userId });

    if (resp && resp?.data && resp?.statusCode === 200) {
      dispatch(setModelVisible(true));
      const data = resp?.data;
      dispatch(setModelContent(data));
      const NudgeContent = {
        // campaignType : campaign_type,
        couponCode: data?.coupon_code,
        instabuyTitle: data?.title,
        instabuyDescription: data?.description,
        // description : data?.description,
        // descriptionAr : data?.description,
        startTime: data?.start_date,
        endTime: data?.end_date,
        TermsAndCond: data?.terms,
      };
      dispatch(setNudgeContent(NudgeContent));
    }
  }

  setTimerCopon(dispatch) {
    if (!this.timer) {
      setIsTimerRunning(dispatch, true);
      this.timer = setTimeout(() => {
        this.getTimerCoupon(dispatch);
        this.timer = null;
        setIsTimerRunning(dispatch, false);
      }, 2000);
    }
  }

  setInstbuyAvailable(dispatch, InstbuyCoupons = {}) {
    const {
      // campaign_type = "",
      code = "",
      description = "",
      description_ar = "",
      end_date_time = "",
      instabuy_description = "",
      instabuy_title = "",
      start_date_time = "",
      term_and_cond = "",
    } = InstbuyCoupons;

    const NudgeContent = {
      // campaignType : campaign_type,
      couponCode: code,
      instabuyTitle: instabuy_title,
      instabuyDescription: instabuy_description,
      description: description,
      descriptionAr: description_ar,
      startTime: start_date_time,
      endTime: end_date_time,
      TermsAndCond: term_and_cond,
    };
    // put check here according to end coupon time
    dispatch(setNudgeVisible(true));
    dispatch(setNudgeContent(NudgeContent));
    dispatch(setIsInstaBuyAvailable(true));
  }

  checkIsInstabuyAvailable(dispatch, avail_coupons) {
    const InstbuyCoupon = avail_coupons.filter(
      (coupon) => coupon.campaign_type === "ACTIVITY_BASED"
    );

    if (InstbuyCoupon && InstbuyCoupon.length > 0) {
      this.setInstbuyAvailable(dispatch, InstbuyCoupon[0]);
    } else {
      // generate instabuy coupon - write the logic here - when to call generate coupon?
      this.setTimerCopon(dispatch);
    }

    // for now pass the dummy data as above logic will not work
    const InstbuyCoupons = {
      actions_serialized: "",
      code: "InstaBuy",
      conditions_serialized: "",
      cust_group_id: "0,1",
      description: "Description of insta buy coupon",
      description_ar: "Arabic - Description of insta buy coupon",
      discount_amount: "0.0000",
      from_date: "2024-05-30 02:46:00",
      name: "UP TO 85% OFF + 10% CASHBACK",
      order_source: "ALL",
      row_id: 14870,
      rule_id: 14372,
      simple_action: "by_percent",
      term_and_cond: "Terms and conditions",
      times_used: 21879,
      start_date_time: "2024-09-20 12:20:10", // new field
      end_date_time: "2024-10-20 14:20:10", // new field
      coupon_type: "INTABUY", // new field
      visible_timer: 0 / 1, // new field
      timer_start: "6", // new field
      to_date: null,
      usage_limit: null,
      usage_per_customer: null,
      website_id: "1,3,4,6,7,8",
    };

    // set dummy instbuy coupon for UT
    // this.setInstbuyAvailable(dispatch, InstbuyCoupons);
  }

  setModelVisibility = (dispatch, isVisible) => {
    dispatch(setModelVisible(isVisible));
  };

  setNudgeVisibility = (dispatch, isVisible) => {
    dispatch(setNudgeVisible(isVisible));
  };

  setNudgeHideByUser = (dispatch, isNudgeHideByUser) => {
    dispatch(setNudgeHideByUser(isNudgeHideByUser));
  };

  setIsInstaBuyAvailable = (dispatch, isInstaBuyAvailable) => {
    dispatch(setIsInstaBuyAvailable(isInstaBuyAvailable));
  };

  setIsTimerRunning = (dispatch, isTimerRunning) => {
    dispatch(setIsTimerRunning(isTimerRunning));
  };
}

export default new InstaBuyCouponDispatcher();
