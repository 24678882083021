export const STATE_LINK = 'link';
export const STATE_DE_LINK = 'deLink';
export const STATE_DE_LINK_SUCCESS= "deLinkSuccess";
export const STATE_VERIFY = 'verify';
export const STATE_SUCCESS = 'success';
export const STATE_NOT_SUCCESS = 'notSucces';
export const STATE_AUTHENTICATION = 'authentication';
export const STATE_CONFRIM_EXISTING = 'confirmExistingAccount';
export const STATE_PURPOSE_LINK = 'link';
export const STATE_PURPOSE_CHANGE = 'change';
